/*  */
:focus { outline: none; }
.principal {padding-top:0.5em;}
.oculto {display:none}
/* header */
.header {margin-top:1%;margin-bottom:1%;}
.header h1 {text-align: center;}
/*.header svg:nth-child(5) {color:#572364} */

/* footer */
.footer {margin-top:0.5em}
.acercade , .soluciones {margin-top:0;}

.soluciones {background-color:#572364;color:#ffffff;font-size:1em;}

/* Colores letras*/
.bg-slate-400 {background-color:#5d5d5d}
.border-slate-400 {border-color: #5d5d5d;}
.bg-green-500 {background-color: #00ce4b}
.border-green-500 {border-color: #00ce4b;}
.bg-yellow-500 { background-color: #ffbe13;}
.border-yellow-500 {border-color: #ffbe13;}

/* Colores letras*/
.border-solid {border-radius: 25px;}

/* genericos  */
p.margen-inferior {margin-bottom: 1em}

/* botones  */
.bg-indigo-600 {background-color:#572364;color:#ffffff;}
.soluciones:hover {
    --tw-bg-opacity: 1;
    background-color: #572364b0;
}
/*.hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: #007A33b0;
}*/

.hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: #572364b0;
}

.focus\:ring-2:focus {--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) #572364;}

.modal-cabecera {display: flex;}

svg.cursor-pointer:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.alarga {display: flex;align-items: center;}

/* BOTON MODAL PUBLI */

.boton-publi-modal{background-color: #16A34A;color:#ffffff}
.boton-publi-modal:hover{background-color: #22c55e;}

.publisobreteclado{display:flex;min-width:300px;max-width:600px;width:98%;height:60px;margin:auto}

/* TEMPORIZADOR */

svg.temporizador {display: inline-block; margin-right: 0.5rem;margin-bottom: 2px;}

.temporizador-contenedor {
  max-width: 60%;
    border: 2px solid #572364;
    padding: 5px;
    border-radius: 10px;
}


/* MENU */

.fullmodal {
  background: #572364;
}
.menu {height: 90vh;}
.menu-texto {margin-left: 10px;}
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.accordion-menu {
  width: 100%;
  max-width: 300px;
  margin: 25px auto;
  background: #fff;
  border-radius: 4px;
}

.accordion-menu svg {display: inline-block;}
.accordion-menu li:last-child .dropdownlink {
  border-bottom: 0;
}
.menu li {text-align: left;}
.menu-temporizador svg {color: white}
.menu ul svg  {margin-left: 20px;}
.dropdownlink {
  cursor: pointer;
  display: block;
  padding: 12px 15px 12px 10px;
  border-bottom: 1px solid #ccc;
  color: #212121;
  position: relative;
  transition: all 0.4s ease-out;}
  .dropdownlink2 {
    
    padding: 6px 15px 6px 10px;
    }
  .dropdownlink i {
    position: absolute;
    top: 17px;
    left: 16px;
  }
  .fa-chevron-down {
    right: 12px;
    left: auto;
  }

.cerrar {color:white}
.submenuItems {
  display: none;
  background: #C8E6C9;}
.submenuItems li {
    border-bottom: 1px solid #B6B6B6;
  }


.submenuItems a {
  display: block;
  color: #727272;
  padding: 12px 12px 12px 45px;
  transition: all 0.4s ease-out;}
.submenuItems a :hover {
    background-color: #CDDC39;
    color: #fff;
  }



  iframe {width:100%;height:100%;border:none;}
  .iframe-container{
    margin: 0 auto;
    max-width:100%;
    width:500px;
    height:500px;
    }

    
    .iconomenu {
      margin-left: 10px;
      background-color: #572364;
      color:white;
    
      }   
/* ADAPTA A MOVILES PEQUEÑOS */

@media screen and (max-height: 600px) {

    .celda {height: 44px; width: 44px;}
    
    .letter-container {font-size: 1.75rem;line-height: 2rem;}
    
    .header img {max-height: 40px;}  
    }


    @media only screen and (max-width: 992px) {
      .iframe-container{
        width: 100%;
        height:400px;
      }
    }
    @media only screen and (max-width: 600px) {
       .iframe-container{
        width: 100%;
        height:500px;
      }
    }